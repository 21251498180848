"use client";

import Link from "next/link";
import React from "react";
import { IconContext } from "react-icons";
import { FaHeart, FaStackOverflow } from "react-icons/fa6";

export default function Footer() {
    return (
        <div className="h-24 pt-4">
            Made with{" "}
            <IconContext.Provider
                value={{
                    className: "inline-react-icon",
                }}
            >
                <FaStackOverflow />
            </IconContext.Provider>{" "}
            and{" "}
            <IconContext.Provider
                value={{
                    className: "inline-react-icon",
                }}
            >
                <FaHeart />
            </IconContext.Provider>{" "}
            by{" "}
            <Link className="underline hover:text-[--action]" href="https://alinarosa.gay">
                Alina Rosa
            </Link>
        </div>
    );
}
