"use client";

import NavBar from "./ui/navbar";
import Link from "next/link";
import { FaStackOverflow, FaHeart } from "react-icons/fa6";
import { IconContext } from "react-icons";
import Footer from "./ui/footer";


export default function Home() {
    return (
        <main className="main-container">
            <NavBar></NavBar>
            <div className="flex flex-col items-center p-2 text-center justify-around gap-12">
                <h1 className="text-3xl lg:text-5xl font-semibold drop-shadow ">
                    Sprawdź, z kim masz najbardziej podobne poglądy.
                </h1>
                <p>
                    Odpowiadając w naszej ankiecie znajdziesz osoby, z którymi
                    masz najbardziej zgodne poglądy. Wszystkie odpowiedzi są
                    anonimowe.
                </p>
                <Link href="/ankieta">
                    <button className="answer-btn-next px-14 breathe levitate change-shadow">
                        Wypełnij ankietę
                    </button>
                </Link>
            </div>
            <Footer></Footer>
        </main>
    );
}
