"use client";

import Link from "next/link";
import React from "react";
import { IconContext } from "react-icons";
import { GiLighthouse } from "react-icons/gi";

export default function NavBar() {

    const links = [
        {
            id: 0,
            link: "metodologia",
            text: "Metodologia"
        },
        {
            id: 1,
            link: "o-nas",
            text: "O Nas"
        },
    ];
    return (
        <div className="flex w-full justify-between h-24 items-center px-8">
            <p className="font-bold text-xl">
            <Link href={"/"}>Latarnik Wyborczy 2025 <IconContext.Provider value={{className: "inline text-4xl"}}> <GiLighthouse/></IconContext.Provider></Link></p>
            <ul className="flex flex-col md:flex-row align-bottom text-end">
                {links.map(({ id, link, text }) => (
                    <li
                        key={id}
                        className="nav-links px-4 cursor-pointer capitalize font-medium hover:scale-105 duration-200 link-underline"
                    >
                        <Link href={link}>{text}</Link>
                    </li>
                ))}
            </ul>
            
        </div>
    );
}
